import { FC, useEffect, useState } from 'react';

import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { Zone } from '@/redux/parcels/parcels.types.ts';
import { getDisplayZone } from '@/utils/zones.util.ts';

type ZoneTableRowProps = {
  zone: Zone;
};

const ZoneTableRow: FC<ZoneTableRowProps> = ({ zone }: ZoneTableRowProps) => {
  const [statusColor, setStatusColor] = useState<string>('');

  useEffect(() => {
    switch (zone.humidityStatus) {
      case 'dry':
        setStatusColor('text-[#d9534f]');
        break;
      case 'moderate':
        setStatusColor('text-[#f0ad4e]');
        break;
      case 'wet':
        setStatusColor('text-[#5cb85c]');
        break;
      case 'saturated':
        setStatusColor('text-[#1a3e5f]');
        break;
      default:
        setStatusColor('');
    }
  }, [zone.humidityStatus]);

  return (
    <TableRow>
      <TableCell>{zone.name}</TableCell>
      <TableCell className={`${statusColor} font-semibold`}>
        {getDisplayZone(zone.humidityStatus)}
      </TableCell>
      <TableCell>{zone.humidityValue}%</TableCell>
    </TableRow>
  );
};

export default ZoneTableRow;
