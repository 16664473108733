import { z } from 'zod';

export const createParcelFormData = z.object({
  userId: z.string().optional(),
  name: z.string().min(1, {
    message: 'Naziv parcele je obavezno polje',
  }),
  numberOfZones: z.number().refine((val) => val > 0, {
    message: 'Broj zona mora biti pozitivan broj veći ili jednak 1',
  }),
  place: z.string().min(1, {
    message: 'Mesto je obavezno polje',
  }),
  country: z.string().min(1, {
    message: 'Država je obavezno polje',
  }),
  weatherForecast: z.string().min(1, {
    message: 'Niste odabrali vremensku prognozu',
  }),
  simCardNumber: z.string().min(1, {
    message: 'Broj SIM kartice je obavezno polje',
  }),
  boundaryForDryLand: z.number().refine((val) => val > 0, {
    message: 'Granica mora biti pozitivan broj',
  }),
  boundaryForWetlands: z.number().refine((val) => val > 0, {
    message: 'Granica mora biti pozitivan broj',
  }),
  humiditySensor: z.boolean(),
  secondHumiditySensor: z.boolean(),
  supplementMixer: z.boolean(),
  supplement: z.boolean(),
  voltage: z.boolean(),
  rawSensorValues: z.boolean(),
  organizationId: z.string().min(1, {
    message: 'Niste odabrali organizaciju',
  }),
});

export const editParcelSchema = z.object({
  id: z.string().min(1),
  userId: z.string().optional(),
  name: z.string().min(1, {
    message: 'Naziv parcele je obavezno polje',
  }),
  numberOfZones: z.number().refine((val) => val > 0, {
    message: 'Broj zona mora biti pozitivan broj veći ili jednak 1',
  }),
  maxBetweenTwoShifts: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message:
        'Max vreme izmedju dve smene mora biti pozitivan broj veći ili jednak 1',
    }),
  place: z.string().min(1, {
    message: 'Mesto je obavezno polje',
  }),
  country: z.string().min(1, {
    message: 'Država je obavezno polje',
  }),
  weatherForecast: z.string().min(1, {
    message: 'Niste odabrali vremensku prognozu',
  }),
  simCardNumber: z.string().min(1, {
    message: 'Broj SIM kartice je obavezno polje',
  }),
  boundaryForDryLand: z.number().refine((val) => val > 0, {
    message: 'Granica mora biti pozitivan broj',
  }),
  boundaryForWetlands: z.number().refine((val) => val > 0, {
    message: 'Granica mora biti pozitivan broj',
  }),
  coordinates: z.string(),
  humiditySensor: z.boolean(),
  secondHumiditySensor: z.boolean(),
  supplementMixer: z.boolean(),
  supplement: z.boolean(),
  voltage: z.boolean(),
  rawSensorValues: z.boolean(),
  numberOfIrrigation: z.boolean(),
  airHumidity: z.boolean(),
  pumpSensor: z.boolean(),
  numberOfZonesSwitch: z.boolean(),
  airTemperature: z.boolean(),
  soilTemperatureSensor: z.boolean(),
  soilSalinity: z.boolean(),
  flowMeter: z.boolean(),
  waterLevelSensor: z.boolean(),
  pHSensor: z.boolean(),
  moreSENSSensors: z.boolean(),
  flowMeterCapacity: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Kapacitet protokomera mora biti broj',
    }),
  flowMeterFactorK: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Faktor K protokomera mora biti broj',
    }),
  flowMeterFactorM: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Fakot M protokomera mora biti broj',
    }),
  pHFactorK: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'pH fakot K mora biti broj',
  }),
  minHumidityForAutomaticIrrigation: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message:
        'Minimalna vlažnost za automatski režim navodnjavanja mora biti broj',
    }),
  optimalHumidityForAutomaticIrrigation: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message:
        'Optimalna vlažnost za automatski režim navodnjavanja mora biti broj',
    }),
  durationOfTheActiveValve: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Trajanje uključenog ventila mora biti broj',
    }),
  timeBetweenValves: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Vreme izmedju ventila kod ciklusa mora biti broj',
    }),
  organizationId: z.string().min(1, {
    message: 'Niste odabrali organizaciju',
  }),
});

export const searchParcelsParcelStatisticFormData = z.object({
  parcelName: z
    .string()
    .nonempty('Odaberite parcelu iz korisnikove organizacije'),
});

export const searchUsersParcelStatisticFormData = z.object({
  userName: z.string().nonempty('Odaberite korisnika'),
});
