import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormDropDown from '@/components/controls/CustomFormDropDown.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import {
  communicationModules,
  fertDeviceZoneNumbers,
} from '@/utils/device.util.ts';

type FERTDeviceInfoFormProps = {
  form: UseFormReturn<any>;
};

const FERTDeviceInfoForm: FC<FERTDeviceInfoFormProps> = ({ form }) => {
  return (
    <>
      <CustomFormInput
        customForm={form}
        name="name"
        labelText="Ime uredjaja"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="color"
        labelText="Boja uredjaja"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-md:w-full"
        divStyle="w-[50px]"
        type="color"
      />
      <CustomFormDropDown
        customForm={form}
        name="communicationModule"
        labelText="Komunikacioni modul"
        formItemStyle="flex w-full flex-col pb-5 px-2 max-w-[520px] mt-2 sm:mt-5"
        items={communicationModules}
        placeholder="Odaberite korisnika"
      />
      <CustomFormInput
        customForm={form}
        name="syncTime"
        labelText="Vreme za sinhronizaciju u minutima"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
        min="1"
      />
      <CustomFormDropDown
        customForm={form}
        name="zoneNumber"
        labelText="Broj zona"
        items={fertDeviceZoneNumbers}
        formItemStyle="flex w-full flex-col px-2 max-w-[520px] mt-2 sm:mt-5"
        placeholder="Odaberite broj zona"
      />
    </>
  );
};

export default FERTDeviceInfoForm;
