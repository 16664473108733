import { DropDownItems } from '@/components/controls/CustomFormDropDown.tsx';
import { CreateFERTDeviceFormData } from '@/components/forms/CreateFERTDeviceForm.tsx';
import { CreateSensDeviceFormData } from '@/components/forms/CreateSensDeviceForm.tsx';
import { CreateStartDeviceFormData } from '@/components/forms/CreateStartDeviceForm.tsx';
import { EditFERTDeviceFormData } from '@/components/forms/EditFERTDeviceForm.tsx';
import { EditSensDeviceFormData } from '@/components/forms/EditSensDeviceForm.tsx';
import { EditStartDeviceFormData } from '@/components/forms/EditStartDeviceForm.tsx';
import {
  AdditionalSensorValuesFERT,
  CreateFERTDeviceData,
  CreateSensDevice,
  CreateStartDeviceData,
  FERTDevice,
  SensDevice,
  StartDevice,
  SupplementForDevice,
} from '@/redux/device/devices.types.ts';

export const defaultSyncTime = '15';

export const defaultZoneNumber = '1';

export const itemsForRadioGroup = [
  { value: 'true', text: 'Uključeno' },
  { value: 'false', text: 'Isključeno' },
];

export const itemsForHumiditySensorValues = [
  { value: '10hs 1', text: '10hs 1' },
  { value: '10hs 2', text: '10hs 2' },
  { value: 'SentekTH 30', text: 'SentekTH 30' },
  { value: 'SentekTHS 30', text: 'SentekTHS 30' },
  { value: 'SentekTH 60', text: 'SentekTH 60' },
  { value: 'SentekTHS 60', text: 'SentekTHS 60' },
  { value: 'SentekTH 90', text: 'SentekTH 90' },
  { value: 'SentekTHS 90', text: 'SentekTHS 90' },
];

export const itemsForFlowMeterRadioGroup = [
  { text: '33 imp/L', value: '33 imp/L' },
  { text: '10 L/impuls', value: '10 L/impuls' },
  { text: '100 L/impuls', value: '100 L/impuls' },
  { text: '1000 L/impuls', value: '1000 L/impuls' },
];

export const communicationModules: DropDownItems[] = [
  {
    value: 'GPRS',
    text: 'GPRS',
  },
  { value: 'LTE', text: 'LTE' },
  { value: 'LoRa', text: 'LoRa' },
];

export const fertDeviceZoneNumbers: DropDownItems[] = [
  { value: '1', text: '1 zona' },
  { value: '2', text: '2 zone' },
  { value: '3', text: '3 zone' },
  { value: '4', text: '4 zone' },
  { value: '5', text: '5 zona' },
  { value: '6', text: '6 zona' },
  { value: '7', text: '7 zona' },
  { value: '8', text: '8 zona' },
  { value: '9', text: '9 zona' },
  { value: '10', text: '10 zona' },
  { value: '11', text: '11 zona' },
  { value: '12', text: '12 zona' },
  { value: '13', text: '13 zona' },
  { value: '14', text: '14 zona' },
  { value: '15', text: '15 zona' },
  { value: '16', text: '16 zona' },
  { value: '17', text: '17 zona' },
  { value: '18', text: '18 zona' },
  { value: '19', text: '19 zona' },
  { value: '20', text: '20 zona' },
];

export const startDeviceZoneNumbers: DropDownItems[] = [
  { value: '1', text: '1 zona' },
  { value: '2', text: '2 zone' },
  { value: '3', text: '3 zone' },
  { value: '4', text: '4 zone' },
  { value: '5', text: '5 zona' },
  { value: '6', text: '6 zona' },
  { value: '7', text: '7 zona' },
  { value: '8', text: '8 zona' },
  { value: '9', text: '9 zona' },
  { value: '10', text: '10 zona' },
];

export const sensDeviceZoneNumbers: DropDownItems[] = [
  { value: '1', text: '1 zona' },
  { value: '2', text: '2 zone' },
  { value: '3', text: '3 zone' },
  { value: '4', text: '4 zone' },
];

export const sensDeviceTypes: DropDownItems[] = [
  {
    value: '30',
    text: '30 cm',
  },
  { value: '60', text: '60 cm' },
  { value: '90', text: '90 cm' },
];

export const transformFERTDeviceData = (
  data: CreateFERTDeviceFormData
): CreateFERTDeviceData => {
  return {
    wiltingPoint: Number(data.wiltingPoint),
    irrigationPoint: Number(data.irrigationPoint),
    fieldCapacity: Number(data.fieldCapacity),
    syncTime: data.syncTime,
    zoneNumber: Number(data.zoneNumber),
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    communicationModule: data.communicationModule,
    pHFactorK: Number(data.pHFactorK),
    pHFactorM: Number(data.pHFactorM),
    supplementOne: data.supplementOne === 'true',
    supplementTwo: data.supplementTwo === 'true',
    supplementThree: data.supplementThree === 'true',
    supplementFour: data.supplementFour === 'true',
    supplementFive: data.supplementFive === 'true',
    supplementMixer: data.supplementMixer === 'true',
    ecSensor: data.ecSensor === 'true',
    ecFactorK: Number(data.ecFactorK),
    flowMeter: data.flowMeter,
    notificationForWrongFlowMeter: JSON.parse(
      data.notificationForWrongFlowMeter
    ),
    pressureSensor: data.pressureSensor === 'true',
    waterLevelSensor: data.waterLevelSensor === 'true',
    pHWater: data.pHWater === 'true',
  };
};

export const transformStartDeviceData = (
  data: CreateStartDeviceFormData
): CreateStartDeviceData => {
  return {
    wiltingPoint: data.wiltingPoint,
    irrigationPoint: data.irrigationPoint,
    fieldCapacity: Number(data.fieldCapacity),
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    humidityOne: data.humidityOne === 'true',
    humidityTwo: data.humidityTwo === 'true',
    supplementMixer: data.supplementMixer === 'true',
    flowMeter: data.flowMeter,
    notificationForWrongFlowMeter:
      data.notificationForWrongFlowMeter === 'true',
    pressureSensor: data.pressureSensor === 'true',
    syncTime: data.syncTime,
    zoneNumber: Number(data.zoneNumber),
  };
};

export const transformStartDeviceForForm = (
  device: StartDevice
): EditStartDeviceFormData => {
  if (!device) {
    return null;
  }

  return {
    id: device.id,
    parcelId: device.parcelId,
    name: device.name,
    color: device.color,
    humidityOne: device.humidity.humidityOne.toString(),
    humidityTwo: device.humidity.humidityTwo.toString(),
    supplementMixer: device.additionalSensorValues.supplementMixer.toString(),
    flowMeter: device.additionalSensorValues.flowMeter,
    notificationForWrongFlowMeter:
      device.additionalSensorValues.notificationForWrongFlowMeter.toString(),
    pressureSensor: device.additionalSensorValues.pressureSensor.toString(),
    wiltingPoint: device.wiltingPoint,
    irrigationPoint: device.irrigationPoint,
    fieldCapacity: device.fieldCapacity,
    syncTime: device.syncTime,
    zoneNumber: device.zoneNumber.toString(),
  };
};

export const transformEditStartDeviceData = (data: {
  device: StartDevice;
  deviceToEdit: EditStartDeviceFormData;
}): StartDevice => {
  const { device, deviceToEdit } = data;
  const supplementMixer = deviceToEdit.supplementMixer === 'true';

  return {
    wiltingPoint: deviceToEdit.wiltingPoint,
    irrigationPoint: deviceToEdit.irrigationPoint,
    fieldCapacity: deviceToEdit.fieldCapacity,
    id: device.id,
    serialNumber: device.serialNumber ? device.serialNumber : '',
    parcelId: deviceToEdit.parcelId,
    name: deviceToEdit.name,
    color: deviceToEdit.color,
    humidity: {
      humidityOne: deviceToEdit.humidityOne === 'true',
      humidityTwo: deviceToEdit.humidityTwo === 'true',
    },
    additionalSensorValues: {
      supplementMixer: deviceToEdit.supplementMixer === 'true',
      flowMeter: deviceToEdit.flowMeter,
      notificationForWrongFlowMeter:
        deviceToEdit.notificationForWrongFlowMeter === 'true',
      pressureSensor: deviceToEdit.pressureSensor === 'true',
    },
    additionalFields: {
      rev: device.additionalFields.rev,
      acRelay: 0,
      mixerRelay24: supplementMixer,
      mainPumpRelay24: device.additionalFields.mainPumpRelay24,
      flowMeterType:
        deviceToEdit.flowMeter === 'true'
          ? device.additionalFields.flowMeterType
          : '',
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
    },
    commentOnEdit: device.commentOnEdit ? device.commentOnEdit : '',
    did: device.did,
    syncTime: deviceToEdit.syncTime,
    zoneNumber: Number(deviceToEdit.zoneNumber),
  };
};

export const transformFERTDeviceForForm = (
  device: FERTDevice
): EditFERTDeviceFormData => {
  if (!device) {
    return null;
  }

  return {
    wiltingPoint: device.wiltingPoint,
    irrigationPoint: device.irrigationPoint,
    fieldCapacity: device.fieldCapacity,
    id: device.id,
    parcelId: device.parcelId,
    name: device.name,
    color: device.color,
    communicationModule: device.communicationModule,
    supplementMixer: device.additionalSensorsValues.supplementMixer.toString(),
    flowMeter: device.additionalSensorsValues.flowMeter,
    notificationForWrongFlowMeter:
      device.additionalSensorsValues.notificationForWrongFlowMeter.toString(),
    pressureSensor: device.additionalSensorsValues.pressureSensor.toString(),
    pHFactorK: device.pHFactorK.toString(),
    pHFactorM: device.pHFactorM.toString(),
    supplementOne: device.supplement.supplementOne.toString(),
    supplementTwo: device.supplement.supplementTwo.toString(),
    supplementThree: device.supplement.supplementThree.toString(),
    supplementFour: device.supplement.supplementFour.toString(),
    supplementFive: device.supplement.supplementFive.toString(),
    ecSensor: device.additionalSensorsValues.ecSensor.toString(),
    ecFactorK: device.additionalSensorsValues.ecFactorK.toString(),
    waterLevelSensor:
      device.additionalSensorsValues.waterLevelSensor.toString(),
    pHWater: device.additionalFields.pHWater.toString(),
    syncTime: device.syncTime,
    zoneNumber: device.zoneNumber.toString(),
  };
};

export const transformEditFERTDeviceData = (data: {
  device: FERTDevice;
  deviceToEdit: EditFERTDeviceFormData;
}): FERTDevice => {
  const { device, deviceToEdit } = data;

  const supplement: SupplementForDevice = {
    supplementOne: deviceToEdit.supplementOne === 'true',
    supplementTwo: deviceToEdit.supplementTwo === 'true',
    supplementThree: deviceToEdit.supplementThree === 'true',
    supplementFour: deviceToEdit.supplementFour === 'true',
    supplementFive: deviceToEdit.supplementFive === 'true',
  };

  const additionalSensorsValues: AdditionalSensorValuesFERT = {
    supplementMixer: deviceToEdit.supplementMixer === 'true',
    ecSensor: deviceToEdit.ecSensor === 'true',
    waterLevelSensor: deviceToEdit.waterLevelSensor === 'true',
    pressureSensor: deviceToEdit.pressureSensor === 'true',
    notificationForWrongFlowMeter:
      deviceToEdit.notificationForWrongFlowMeter === 'true',
    flowMeter: deviceToEdit.flowMeter,
    ecFactorK: Number(deviceToEdit.ecFactorK),
  };

  return {
    id: device.id,
    wiltingPoint: deviceToEdit.wiltingPoint,
    irrigationPoint: deviceToEdit.irrigationPoint,
    fieldCapacity: deviceToEdit.fieldCapacity,
    serialNumber: device.serialNumber ? device.serialNumber : '',
    parcelId: deviceToEdit.parcelId,
    name: deviceToEdit.name,
    color: deviceToEdit.color,
    communicationModule: deviceToEdit.communicationModule,
    pHFactorK: Number(deviceToEdit.pHFactorK),
    pHFactorM: Number(deviceToEdit.pHFactorM),
    additionalFields: {
      rev: device.additionalFields.rev,
      acRelay: 0,
      mainPumpRelay24: device.additionalFields.mainPumpRelay24,
      mixerRelay24: device.additionalFields.mixerRelay24,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      pHControl: supplement.supplementOne,
      ecControl:
        supplement.supplementTwo ||
        supplement.supplementThree ||
        supplement.supplementFour ||
        supplement.supplementFive,
      flowProportional: device.additionalFields.flowProportional,
      fixedPumpPowerMod2:
        supplement.supplementOne ||
        supplement.supplementTwo ||
        supplement.supplementThree ||
        supplement.supplementFour ||
        supplement.supplementFive,
      pHWater: deviceToEdit.pHWater === 'true',
      flowMeterType: deviceToEdit.flowMeter
        ? device.additionalFields.flowMeterType
        : '',
    },
    commentOnEdit: device.commentOnEdit ? device.commentOnEdit : '',
    supplement,
    additionalSensorsValues,
    did: device.did,
    syncTime: deviceToEdit.syncTime,
    zoneNumber: Number(deviceToEdit.zoneNumber),
  };
};

export const flowMeterTypes: DropDownItems[] = [
  { text: '(33imp/L)', value: '(33imp/L)' },
  { text: 'Interrup', value: 'Interrup' },
  { text: 'Toggling', value: 'Toggling' },
  { text: '10L/impuls', value: '10L/impuls' },
  { text: '100L/impuls', value: '100L/impuls' },
  { text: '1000L/impuls', value: '1000L/impuls' },
];

export const airTempAndHumidTypes = [
  {
    text: 'BGT',
    value: 'BGT',
  },
  {
    text: 'SHT1',
    value: 'SHT1',
  },
];

export const batteryTypesSENS: DropDownItems[] = [
  {
    text: 'Solarni panel',
    value: 'Solarni panel',
  },
  {
    text: 'Li-Ion Battery + Solar',
    value: 'Li-Ion Battery + Solar',
  },
  {
    text: 'Lead + Solar',
    value: 'Lead + Solar',
  },
  {
    text: '12V DC Adapter',
    value: '12V DC Adapter',
  },
];

export const transformSensDeviceForForm = (
  device: SensDevice
): EditSensDeviceFormData => {
  if (!device) {
    return null;
  }

  const { sensSensorType, humidity, additionalSensorsValues } = device;

  const getSoilHumiditySensor = (): string => {
    if (sensSensorType === 0 && humidity.humidityOne) {
      return '10hs 1';
    }
    if (sensSensorType === 0 && humidity.humidityTwo) {
      return '10hs 2';
    }
    if (sensSensorType === 30) {
      return additionalSensorsValues.soilSalinity
        ? 'SentekTHS 30'
        : 'SentekTH 30';
    }
    if (sensSensorType === 60) {
      return additionalSensorsValues.soilSalinity
        ? 'SentekTHS 60'
        : 'SentekTH 60';
    }
    if (sensSensorType === 90) {
      return additionalSensorsValues.soilSalinity
        ? 'SentekTHS 90'
        : 'SentekTH 90';
    }
    return '';
  };

  return {
    id: device.id,
    parcelId: device.parcelId,
    name: device.name,
    color: device.color,
    standbyModeTime: device.standbyModeTime.toString(),
    sensSensorType: sensSensorType.toString(),
    humidityOne: humidity.humidityOne.toString(),
    humidityTwo: humidity.humidityTwo.toString(),
    flowMeter: additionalSensorsValues.flowMeter.toString(),
    soilTemperature: additionalSensorsValues.soilTemperature.toString(),
    soilSalinity: additionalSensorsValues.soilSalinity.toString(),
    soilPH: additionalSensorsValues.soilPH.toString(),
    airTemperature: additionalSensorsValues.airTemperature.toString(),
    airHumidity: additionalSensorsValues.airHumidity.toString(),
    windDirection: additionalSensorsValues.windDirection.toString(),
    windSpeed: additionalSensorsValues.windSpeed.toString(),
    solarRadiation: additionalSensorsValues.solarRadiation.toString(),
    amountOfPrecipitation:
      additionalSensorsValues.amountOfPrecipitation.toString(),
    batteryVoltage: additionalSensorsValues.batteryVoltage.toString(),
    notificationForWrongFlowMeter:
      additionalSensorsValues.notificationForWrongFlowMeter.toString(),
    pressureSensor: additionalSensorsValues.pressureSensor.toString(),
    batteryType: device.additionalFields.batteryType,
    wiltingPoint: device.wiltingPoint,
    irrigationPoint: device.irrigationPoint,
    fieldCapacity: device.fieldCapacity,
    leafMoistureSensor:
      additionalSensorsValues.leafMoistureSensor?.toString() ?? 'false',
    soilHumiditySensor: getSoilHumiditySensor(),
    syncTime: device.syncTime,
    zoneNumber: device.zoneNumber.toString(),
  };
};

export const transformSensDeviceData = (
  data: CreateSensDeviceFormData
): CreateSensDevice => {
  const mapSoilHumiditySensor = (sensor: string) => {
    const sensorMapping: Record<
      string,
      {
        humidityOne: boolean;
        humidityTwo: boolean;
        sensSensorType: number;
        soilTemperature: boolean;
        soilSalinity: boolean;
      }
    > = {
      '10hs 1': {
        humidityOne: true,
        humidityTwo: false,
        sensSensorType: 0,
        soilTemperature: false,
        soilSalinity: false,
      },
      '10hs 2': {
        humidityOne: false,
        humidityTwo: true,
        sensSensorType: 0,
        soilTemperature: false,
        soilSalinity: false,
      },
      'SentekTH 30': {
        humidityOne: false,
        humidityTwo: false,
        sensSensorType: 30,
        soilTemperature: true,
        soilSalinity: false,
      },
      'SentekTHS 30': {
        humidityOne: false,
        humidityTwo: false,
        sensSensorType: 30,
        soilTemperature: true,
        soilSalinity: true,
      },
      'SentekTH 60': {
        humidityOne: false,
        humidityTwo: false,
        sensSensorType: 60,
        soilTemperature: true,
        soilSalinity: false,
      },
      'SentekTHS 60': {
        humidityOne: false,
        humidityTwo: false,
        sensSensorType: 60,
        soilTemperature: true,
        soilSalinity: true,
      },
      'SentekTH 90': {
        humidityOne: false,
        humidityTwo: false,
        sensSensorType: 90,
        soilTemperature: true,
        soilSalinity: false,
      },
      'SentekTHS 90': {
        humidityOne: false,
        humidityTwo: false,
        sensSensorType: 90,
        soilTemperature: true,
        soilSalinity: true,
      },
    };

    return (
      sensorMapping[sensor] || {
        humidityOne: false,
        humidityTwo: false,
        sensSensorType: Number(data.sensSensorType),
        soilTemperature: false,
        soilSalinity: false,
      }
    );
  };

  const {
    humidityOne,
    humidityTwo,
    sensSensorType,
    soilTemperature,
    soilSalinity,
  } = mapSoilHumiditySensor(data.soilHumiditySensor);

  return {
    wiltingPoint: Number(data.wiltingPoint),
    irrigationPoint: Number(data.irrigationPoint),
    fieldCapacity: Number(data.fieldCapacity),
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    sensSensorType,
    standbyModeTime: Number(data.standbyModeTime),
    humidityOne,
    humidityTwo,
    flowMeter: data.flowMeter === 'true',
    soilTemperature,
    soilSalinity,
    soilPH: data.soilPH === 'true',
    airTemperature: data.airTemperature === 'true',
    airHumidity: data.airHumidity === 'true',
    windDirection: data.windDirection === 'true',
    windSpeed: data.windSpeed === 'true',
    solarRadiation: data.solarRadiation === 'true',
    amountOfPrecipitation: data.amountOfPrecipitation === 'true',
    batteryVoltage: data.batteryVoltage === 'true',
    notificationForWrongFlowMeter:
      data.notificationForWrongFlowMeter === 'true',
    pressureSensor: data.pressureSensor === 'true',
    batteryType: data.batteryType,
    leafMoistureSensor: data.leafMoistureSensor === 'true',
    syncTime: data.syncTime,
    zoneNumber: Number(data.zoneNumber),
  };
};

export const transformEditSensDeviceData = (data: {
  device: SensDevice;
  deviceToEdit: EditSensDeviceFormData;
}): SensDevice => {
  const { device, deviceToEdit } = data;

  const additionalSensorsValues = {
    flowMeter: deviceToEdit.flowMeter === 'true',
    pressureSensor: deviceToEdit.pressureSensor === 'true',
    airHumidity: deviceToEdit.airHumidity === 'true',
    soilPH: deviceToEdit.soilPH === 'true',
    notificationForWrongFlowMeter:
      deviceToEdit.notificationForWrongFlowMeter === 'true',
    airTemperature: deviceToEdit.airTemperature === 'true',
    amountOfPrecipitation: deviceToEdit.amountOfPrecipitation === 'true',
    batteryVoltage: deviceToEdit.batteryVoltage === 'true',
    solarRadiation: deviceToEdit.solarRadiation === 'true',
    windDirection: deviceToEdit.windDirection === 'true',
    windSpeed: deviceToEdit.windSpeed === 'true',
    leafMoistureSensor: deviceToEdit.leafMoistureSensor === 'true',
  };

  const determineSoilHumiditySensor = (soilHumiditySensor: string) => {
    const sensorMapping = {
      '10hs 1': { dekagonHS1: true, humidityOne: true, sensSensorType: 0 },
      '10hs 2': { dekagonHS2: true, humidityTwo: true, sensSensorType: 0 },
      'SentekTH 30': {
        sentekTH30: true,
        soilTemperature: true,
        sensSensorType: 30,
      },
      'SentekTHS 30': {
        sentekTHS30: true,
        soilTemperature: true,
        soilSalinity: true,
        sensSensorType: 30,
      },
      'SentekTH 60': {
        sentekTH60: true,
        soilTemperature: true,
        sensSensorType: 60,
      },
      'SentekTHS 60': {
        sentekTHS60: true,
        soilTemperature: true,
        soilSalinity: true,
        sensSensorType: 60,
      },
      'SentekTH 90': {
        sentekTH90: true,
        soilTemperature: true,
        sensSensorType: 90,
      },
      'SentekTHS 90': {
        sentekTHS90: true,
        soilTemperature: true,
        soilSalinity: true,
        sensSensorType: 90,
      },
    };
    return sensorMapping[soilHumiditySensor] || {};
  };

  const {
    dekagonHS1 = false,
    dekagonHS2 = false,
    humidityOne = false,
    humidityTwo = false,
    soilSalinity = false,
    soilTemperature = false,
    sensSensorType = 0,
    sentekTH30 = false,
    sentekTHS30 = false,
    sentekTH60 = false,
    sentekTHS60 = false,
    sentekTH90 = false,
    sentekTHS90 = false,
  } = determineSoilHumiditySensor(deviceToEdit.soilHumiditySensor);

  return {
    wiltingPoint: deviceToEdit.wiltingPoint,
    irrigationPoint: deviceToEdit.irrigationPoint,
    fieldCapacity: deviceToEdit.fieldCapacity,
    id: device.id,
    parcelId: deviceToEdit.parcelId,
    name: deviceToEdit.name,
    color: deviceToEdit.color,
    serialNumber: device.serialNumber ? device.serialNumber : '',
    standbyModeTime: Number(deviceToEdit.standbyModeTime),
    additionalFields: {
      rev: device.additionalFields.rev,
      dcRelay: 0,
      sentekTH30,
      sentekTHS30,
      sentekTH60,
      sentekTHS60,
      sentekTH90,
      sentekTHS90,
      airTempAndHumidType:
        additionalSensorsValues.airTemperature &&
        additionalSensorsValues.airHumidity
          ? device.additionalFields.airTempAndHumidType
          : '',
      dekagonHS1,
      dekagonHS2,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      batteryType: deviceToEdit.batteryType,
      flowMeterType:
        deviceToEdit.flowMeter === 'true'
          ? device.additionalFields.flowMeterType
          : '',
    },
    additionalSensorsValues: {
      ...additionalSensorsValues,
      soilSalinity,
      soilTemperature,
    },
    sensSensorType,
    humidity: { humidityOne, humidityTwo },
    commentOnEdit: device.commentOnEdit ? device.commentOnEdit : '',
    did: device.did,
    syncTime: deviceToEdit.syncTime,
    zoneNumber: Number(deviceToEdit.zoneNumber),
  };
};
