import { FC, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { useAppSelector } from '@/redux/hooks.ts';
import { getOrganizations } from '@/redux/organizations/organizations.selectors.ts';
import { languages } from '@/utils/languages.ts';

type UserInfoFormProps = {
  form: UseFormReturn<any>;
  isEdit?: boolean;
};

const UserInfoForm: FC<UserInfoFormProps> = ({ form, isEdit }) => {
  const organizations = useAppSelector(getOrganizations);
  const organizationsComboboxContent: ComboboxItemType[] = organizations.map(
    (organization) => ({
      value: organization.id,
      label: organization.name,
    })
  );

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const selectedOrganizationId = form.watch('organizationId');

  useEffect(() => {
    if (selectedOrganizationId) {
      const organization = organizations.find(
        (org) => org.id === selectedOrganizationId
      );
      form.setValue('organizationName', organization ? organization.name : '');
    }
  }, [selectedOrganizationId, form, organizations]);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <CustomFormInput
        customForm={form}
        name="name"
        labelText="Ime"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="surname"
        labelText="Prezime"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="email"
        labelText="Email"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-sm:w-full md:w-[500px]"
        type="text"
      />
      {!isEdit && (
        <CustomFormInput
          customForm={form}
          name="password"
          labelText="Lozinka"
          formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
          labelStyle="max-sm:w-full sm:w-[300px]"
          divStyle="max-sm:w-full md:w-[500px]"
          type={showPassword ? 'text' : 'password'}
          togglePasswordVisibility={togglePasswordVisibility}
          showPassword={showPassword}
        />
      )}
      <CustomFormInput
        customForm={form}
        name="phoneNumber"
        labelText="Telefon"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormCombobox
        customForm={form}
        name="organizationId"
        labelText="Organizacija/Domaćinstvo/Ime Prezime vlasnika"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
        items={organizationsComboboxContent}
        placeholder="Odaberite organizaciju"
      />
      {isEdit && (
        <CustomFormInput
          customForm={form}
          name="organizationName"
          labelText="Izmenite naziv organizacije"
          formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
          labelStyle="max-sm:w-full sm:w-[300px]"
          divStyle="max-md:w-full md:w-[500px]"
          type="text"
        />
      )}
      <CustomFormCombobox
        customForm={form}
        name="wayOfInforming"
        labelText="Način informisanja"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
        items={[
          { value: 'Email', label: 'Email' },
          { value: 'SMS', label: 'SMS' },
        ]}
        placeholder="Odaberite način informisanja"
      />
      <CustomFormCombobox
        customForm={form}
        name="language"
        labelText="Jezik na kom korisnik koristi aplikaciju"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
        items={languages}
        placeholder="Odaberite jezik"
      />
    </>
  );
};

export default UserInfoForm;
