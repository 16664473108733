import { FC } from 'react';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { compareByNameLocale } from '@/lib/utils.ts';
import { SensDevice } from '@/redux/device/devices.types.ts';

type ZoneHumidityCardProps = {
  zone: number;
};

type SensDeviceCardProps = {
  device: SensDevice;
};

type SensDevicesHumidityStatisticsProps = {
  sensDevices: SensDevice[];
};

const ZoneHumidityCard: FC<ZoneHumidityCardProps> = ({ zone }) => {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">
          Vlažnost zona: {zone}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">5 %</div>
      </CardContent>
    </Card>
  );
};

const SensDeviceCard: FC<SensDeviceCardProps> = ({ device }) => {
  return (
    <Card className="mt-5">
      <CardHeader>
        <CardTitle>SENS uredjaj: {device.name}</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-4 grid-cols-4 max-sm:grid-cols-1">
        <ZoneHumidityCard zone={1} />
      </CardContent>
    </Card>
  );
};

const SensDevicesHumidityStatistics: FC<SensDevicesHumidityStatisticsProps> = ({
  sensDevices,
}) => {
  return (
    <AccordionItem value="humiditySensDevices">
      <AccordionTrigger className="text-start">
        Podaci o vlažnosti zemljišta za SENS uredjaje
      </AccordionTrigger>
      <AccordionContent>
        <div className="flex flex-row flex-wrap gap-5 max-sm:flex-col">
          {sensDevices
            .sort(compareByNameLocale)
            .map((device: SensDevice, idx) => (
              <SensDeviceCard
                device={device}
                key={`humidity-device-${device.name}-${idx + 1}`}
              />
            ))}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default SensDevicesHumidityStatistics;
