import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { getCitiesApi } from '@/redux/countries/countries.actions.ts';
import { CitiesResponse } from '@/redux/countries/countries.types.ts';
import { createDistributor } from '@/redux/distributors/distributors.actions.ts';
import { CreateDistributorResponse } from '@/redux/distributors/distributors.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { countries } from '@/utils/countryUtil.ts';
import { createDistributorSchema } from '@/zod/distributor.types.zod.ts';

export type CreateDistributorFormData = {
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
  address: string;
  place: string;
  country: string;
};

const AddDistributorForm: FC = () => {
  const form = useForm({
    resolver: zodResolver(createDistributorSchema),
    defaultValues: {
      name: '',
      email: '',
      password: '',
      phoneNumber: '',
      address: '',
      place: '',
      country: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const breadcrumbs = useAppSelector(getBreadcrumbs);
  const countriesComboboxContent: ComboboxItemType[] = countries.map(
    (country) => {
      return {
        value: country,
        label: country,
      };
    }
  );
  const selectedCountry = useWatch({ control: form.control, name: 'country' });
  const [cities, setCities] = useState<ComboboxItemType[]>([]);

  const handleErrorResponse = (
    response: CreateDistributorResponse | CitiesResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  useEffect(() => {
    const fetchCities = async () => {
      const response = await dispatch(getCitiesApi(selectedCountry)).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }

      setCities(response.content.map((city) => ({ value: city, label: city })));
    };

    if (selectedCountry) {
      fetchCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  const onSubmit = async (data: CreateDistributorFormData) => {
    // @ts-ignore
    const response = await dispatch(createDistributor(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Distributer je uspešno kreiran');
    onGiveUpClick();
  };

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-col">
          <CustomFormInput
            customForm={form}
            name="name"
            labelText="Naziv"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full sm:w-[300px]"
            divStyle="max-md:w-full md:w-[500px]"
            type="text"
          />
          <CustomFormInput
            customForm={form}
            name="email"
            labelText="Email"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full sm:w-[300px]"
            divStyle="max-md:w-full md:w-[500px]"
            type="text"
          />
          <CustomFormInput
            customForm={form}
            name="password"
            labelText="Lozinka"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full sm:w-[300px]"
            divStyle="max-md:w-full md:w-[500px]"
            type="password"
          />
          <CustomFormInput
            customForm={form}
            name="phoneNumber"
            labelText="Telefon"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full sm:w-[300px]"
            divStyle="max-md:w-full md:w-[500px]"
            type="text"
          />
          <CustomFormCombobox
            customForm={form}
            name="country"
            labelText="Država"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full w-[300px]"
            buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
            items={countriesComboboxContent}
            placeholder="Odaberite državu"
          />
          <CustomFormCombobox
            customForm={form}
            name="place"
            labelText="Mesto"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full w-[300px]"
            buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
            items={cities}
            placeholder="Odaberite grad"
          />
          <CustomFormInput
            customForm={form}
            name="address"
            labelText="Adresa"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full sm:w-[300px]"
            divStyle="max-md:w-full md:w-[500px]"
            type="text"
          />
        </div>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText="Dodaj distributera"
        />
      </form>
    </Form>
  );
};

export default AddDistributorForm;
