import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import StartDeviceHumidityForm from '@/components/forms/devices/StartDeviceHumidityForm.tsx';
import StartDeviceInfoForm from '@/components/forms/devices/StartDeviceInfoForm.tsx';
import StartDeviceSensorsForm from '@/components/forms/devices/StartDeviceSensorsForm.tsx';
import CreateParcelBoundaryForm from '@/components/forms/parcels/CreateParcelBoundaryForm.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { updateStartDevice } from '@/redux/device/devices.actions.ts';
import { getStartDeviceForEdit } from '@/redux/device/devices.selectors.ts';
import { CreateDeviceResponse } from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { defaultSyncTime, defaultZoneNumber } from '@/utils/device.util.ts';
import { castStringToNumber } from '@/utils/parcels.ts';
import { editStartDeviceSchema } from '@/zod/device.types.zod.ts';

export type EditStartDeviceFormData = {
  id: string;
  parcelId: string;
  name: string;
  color: string;
  humidityOne: string;
  humidityTwo: string;
  supplementMixer: string;
  flowMeter: string;
  notificationForWrongFlowMeter: string;
  pressureSensor: string;
  wiltingPoint: number;
  irrigationPoint: number;
  fieldCapacity: number;
  syncTime: string;
  zoneNumber: string;
};

type EditStartDeviceFormProps = {
  transformedStartDevice: EditStartDeviceFormData;
};

const EditStartDeviceForm: FC<EditStartDeviceFormProps> = ({
  transformedStartDevice,
}) => {
  const startDevice = useAppSelector(getStartDeviceForEdit);
  const params = useParams();
  const form = useForm({
    resolver: zodResolver(editStartDeviceSchema),
    defaultValues: {
      id: params.deviceId,
      parcelId: params.parcelId,
      name: '',
      color: '#4613ad',
      humidityOne: 'false',
      humidityTwo: 'false',
      supplementMixer: 'true',
      flowMeter: 'true',
      notificationForWrongFlowMeter: 'true',
      pressureSensor: 'false',
      commentOnEdit: '',
      wiltingPoint: 0,
      irrigationPoint: 0,
      fieldCapacity: 0,
      syncTime: defaultSyncTime,
      zoneNumber: defaultZoneNumber,
    },
  });
  const [activeAccordion, setActiveAccordion] = useState<string>('infoForm');
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const breadcrumbs = useAppSelector(getBreadcrumbs);

  const handleErrorResponse = (response: CreateDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  const onSubmit = async (data: EditStartDeviceFormData) => {
    const dataToSend = {
      device: startDevice,
      deviceToEdit: data,
    };
    // @ts-ignore
    const response = await dispatch(updateStartDevice(dataToSend)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno ažuriran uredjaj');
    onGiveUpClick();
  };

  useEffect(() => {
    if (transformedStartDevice) {
      form.reset({
        id: params.deviceId,
        parcelId: params.parcelId,
        name: transformedStartDevice.name,
        color: transformedStartDevice.color,
        humidityOne: transformedStartDevice.humidityOne,
        humidityTwo: transformedStartDevice.humidityTwo,
        supplementMixer: transformedStartDevice.supplementMixer,
        flowMeter: transformedStartDevice.flowMeter,
        notificationForWrongFlowMeter:
          transformedStartDevice.notificationForWrongFlowMeter,
        pressureSensor: transformedStartDevice.pressureSensor,
        wiltingPoint: transformedStartDevice.wiltingPoint,
        irrigationPoint: transformedStartDevice.irrigationPoint,
        fieldCapacity: transformedStartDevice.fieldCapacity,
        syncTime: transformedStartDevice.syncTime,
        zoneNumber: transformedStartDevice.zoneNumber,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedStartDevice]);

  const handleErrorAccordion = () => {
    const { errors } = form.formState;

    if (Object.keys(errors).length === 0) {
      return;
    }

    if (errors.name || errors.color || errors.zoneNumber) {
      setActiveAccordion('infoForm');

      return;
    }

    if (errors.wiltingPoint || errors.irrigationPoint || errors.fieldCapacity) {
      setActiveAccordion('boundariesForm');
    }

    setActiveAccordion('sensorsForm');
  };

  const handleFormSubmit = async () => {
    castStringToNumber(form);
    const isValid = await form.trigger();
    if (!isValid) {
      handleErrorAccordion();
      return;
    }

    form.handleSubmit(onSubmit)();
  };

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <Accordion
          value={activeAccordion}
          onValueChange={(value) => setActiveAccordion(value || '')}
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>Osnovni podaci uredjaja</AccordionTrigger>
            <AccordionContent>
              <StartDeviceInfoForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="sensorsForm">
            <AccordionTrigger>Senzori</AccordionTrigger>
            <AccordionContent>
              <StartDeviceHumidityForm form={form} />
              <StartDeviceSensorsForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="boundariesForm">
            <AccordionTrigger>Granice za zemljište</AccordionTrigger>
            <AccordionContent>
              <CreateParcelBoundaryForm form={form} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText="Izmeni uredjaj"
        />
      </form>
    </Form>
  );
};

export default EditStartDeviceForm;
