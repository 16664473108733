import { createSlice } from '@reduxjs/toolkit';

type CountriesInitialState = {
  cities: string[];
};

const countriesInitialState: CountriesInitialState = {
  cities: [],
};

const countriesSlice = createSlice({
  name: 'countriesSlice',
  initialState: countriesInitialState,
  reducers: {
    setCities: (state, action) => {
      state.cities = [...state.cities, ...action.payload];
    },
  },
});

export const { setCities } = countriesSlice.actions;

export default countriesSlice.reducer;
