import { FC, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import ZonesTableSection from '@/components/layout/zones/ZonesTableSection.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getParcelById } from '@/redux/parcels/parcels.actions.ts';
import { GetParcelByIdResponse } from '@/redux/parcels/parcels.types.ts';

const ParcelZonesHumidityPage: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const router: NavigateFunction = useNavigate();

  const [pageTitle, setPageTitle] = useState<string>('Ime odabrane parcele');

  const handleErrorResponse = (response: GetParcelByIdResponse): void => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const getParcel = async (): Promise<void> => {
      if (!params.parcelId) {
        return;
      }

      const parcelResponse: GetParcelByIdResponse = await dispatch(
        getParcelById(params.parcelId)
      ).unwrap();

      if (!parcelResponse.success) {
        handleErrorResponse(parcelResponse);
        return;
      }

      setPageTitle(parcelResponse.content.name);
    };

    getParcel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0">
          <div className="flex flex-row max-sm:flex-col sm:justify-between items-center max-sm:items-end">
            <p className="text-xl font-bold">{pageTitle}</p>
          </div>
          <ZonesTableSection />
        </div>
      </div>
    </div>
  );
};

export default ParcelZonesHumidityPage;
