import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import FERTDeviceFactorForm from '@/components/forms/devices/FERTDeviceFactorForm.tsx';
import FERTDeviceInfoForm from '@/components/forms/devices/FERTDeviceInfoForm.tsx';
import FERTDeviceSensorsForm from '@/components/forms/devices/FERTDeviceSensorsForm.tsx';
import FERTDeviceSupplementForm from '@/components/forms/devices/FERTDeviceSupplementForm.tsx';
import CreateParcelBoundaryForm from '@/components/forms/parcels/CreateParcelBoundaryForm.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { updateFERTDevice } from '@/redux/device/devices.actions.ts';
import { getFERTDeviceForEdit } from '@/redux/device/devices.selectors.ts';
import { CreateDeviceResponse } from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { defaultSyncTime, defaultZoneNumber } from '@/utils/device.util.ts';
import { castStringToNumber } from '@/utils/parcels.ts';
import { editFERTDeviceSchema } from '@/zod/device.types.zod.ts';

export type EditFERTDeviceFormData = {
  id: string;
  parcelId: string;
  name: string;
  color: string;
  communicationModule: string;
  pHFactorK: string;
  pHFactorM: string;
  supplementOne: string;
  supplementTwo: string;
  supplementThree: string;
  supplementFour: string;
  supplementFive: string;
  supplementMixer: string;
  ecSensor: string;
  ecFactorK: string;
  flowMeter: string;
  notificationForWrongFlowMeter: string;
  pressureSensor: string;
  waterLevelSensor: string;
  pHWater: string;
  wiltingPoint: number;
  irrigationPoint: number;
  fieldCapacity: number;
  syncTime: string;
  zoneNumber: string;
};

type EditFERTDeviceFormProps = {
  transformedFERTDevice: EditFERTDeviceFormData;
};

const EditFERTDeviceForm: FC<EditFERTDeviceFormProps> = ({
  transformedFERTDevice,
}) => {
  const device = useAppSelector(getFERTDeviceForEdit);
  const params = useParams();
  const form = useForm({
    resolver: zodResolver(editFERTDeviceSchema),
    defaultValues: {
      id: '',
      parcelId: params.parcelId as string,
      name: '',
      color: '#4613ad',
      communicationModule: 'LTE',
      pHFactorK: '1',
      pHFactorM: '0',
      supplementOne: 'true',
      supplementTwo: 'true',
      supplementThree: 'true',
      supplementFour: 'false',
      supplementFive: 'false',
      supplementMixer: 'false',
      ecSensor: 'true',
      ecFactorK: '1',
      flowMeter: '(33imp/L)',
      notificationForWrongFlowMeter: 'true',
      pressureSensor: 'false',
      waterLevelSensor: 'false',
      pHWater: 'false',
      commentOnEdit: '',
      wiltingPoint: 0,
      irrigationPoint: 0,
      fieldCapacity: 0,
      syncTime: defaultSyncTime,
      zoneNumber: defaultZoneNumber,
    },
  });
  const [activeAccordion, setActiveAccordion] = useState<string>('infoForm');
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const breadcrumbs = useAppSelector(getBreadcrumbs);

  const handleErrorResponse = (response: CreateDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  const onSubmit = async (data: EditFERTDeviceFormData) => {
    const dataToSend = {
      deviceToEdit: data,
      device,
    };
    // @ts-ignore
    const response = await dispatch(updateFERTDevice(dataToSend)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno ažuriran uredjaj');
    onGiveUpClick();
  };

  useEffect(() => {
    if (transformedFERTDevice) {
      form.reset({
        id: params.deviceId,
        parcelId: params.parcelId,
        name: transformedFERTDevice.name,
        color: transformedFERTDevice.color,
        communicationModule: transformedFERTDevice.communicationModule,
        supplementMixer: transformedFERTDevice.supplementMixer,
        flowMeter: transformedFERTDevice.flowMeter,
        notificationForWrongFlowMeter:
          transformedFERTDevice.notificationForWrongFlowMeter,
        pressureSensor: transformedFERTDevice.pressureSensor,
        pHFactorK: transformedFERTDevice.pHFactorK,
        pHFactorM: transformedFERTDevice.pHFactorM,
        supplementOne: transformedFERTDevice.supplementOne,
        supplementTwo: transformedFERTDevice.supplementTwo,
        supplementThree: transformedFERTDevice.supplementThree,
        supplementFour: transformedFERTDevice.supplementFour,
        supplementFive: transformedFERTDevice.supplementFive,
        ecSensor: transformedFERTDevice.ecSensor,
        ecFactorK: transformedFERTDevice.ecFactorK,
        waterLevelSensor: transformedFERTDevice.waterLevelSensor,
        pHWater: transformedFERTDevice.pHWater,
        wiltingPoint: transformedFERTDevice.wiltingPoint,
        irrigationPoint: transformedFERTDevice.irrigationPoint,
        fieldCapacity: transformedFERTDevice.fieldCapacity,
        syncTime: transformedFERTDevice.syncTime,
        zoneNumber: transformedFERTDevice.zoneNumber,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedFERTDevice]);

  const handleErrorAccordion = () => {
    const { errors } = form.formState;

    if (
      errors.name ||
      errors.color ||
      errors.communicationModule ||
      errors.zoneNumber
    ) {
      setActiveAccordion('infoForm');

      return;
    }

    if (errors.pHFactorK || errors.pHFactorM || errors.ecFactorK) {
      setActiveAccordion('factorsForm');

      return;
    }

    if (
      errors.supplementOne ||
      errors.supplementMixer ||
      errors.supplementTwo ||
      errors.supplementThree ||
      errors.supplementFive ||
      errors.supplementFour
    ) {
      setActiveAccordion('supplementForm');

      return;
    }

    if (
      errors.ecSensor ||
      errors.pressureSensor ||
      errors.waterLevelSensor ||
      errors.pHWater ||
      errors.notificationForWrongFlowMeter ||
      errors.flowMeter
    ) {
      setActiveAccordion('sensorsForm');
    }

    if (errors.wiltingPoint || errors.irrigationPoint || errors.fieldCapacity) {
      setActiveAccordion('boundariesForm');
    }
  };

  const handleFormSubmit = async () => {
    castStringToNumber(form);
    const isValid = await form.trigger();
    if (!isValid) {
      handleErrorAccordion();
      return;
    }
    form.handleSubmit(onSubmit)();
  };

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <Accordion
          value={activeAccordion}
          onValueChange={(value) => setActiveAccordion(value || '')}
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>Osnovni podaci uredjaja</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceInfoForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="factorsForm">
            <AccordionTrigger>Podešavanja koeficijenata</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceFactorForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="supplementForm">
            <AccordionTrigger>Prihrana</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceSupplementForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="sensorsForm">
            <AccordionTrigger>Senzori</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceSensorsForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="boundariesForm">
            <AccordionTrigger>Granice za zemljište</AccordionTrigger>
            <AccordionContent>
              <CreateParcelBoundaryForm form={form} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText="Izmeni uredjaj"
        />
      </form>
    </Form>
  );
};

export default EditFERTDeviceForm;
