import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormDropDown from '@/components/controls/CustomFormDropDown.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import {
  batteryTypesSENS,
  sensDeviceZoneNumbers,
} from '@/utils/device.util.ts';

type SensDeviceInfoFormProps = {
  form: UseFormReturn<any>;
};

const SensDeviceInfoForm: FC<SensDeviceInfoFormProps> = ({ form }) => {
  return (
    <>
      <CustomFormInput
        customForm={form}
        name="name"
        labelText="Ime uredjaja"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="color"
        labelText="Boja uredjaja"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-md:w-full"
        divStyle="w-[50px]"
        type="color"
      />
      <CustomFormInput
        customForm={form}
        name="standbyModeTime"
        labelText="Vreme u standBy modu"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
        min="0"
      />
      <CustomFormInput
        customForm={form}
        name="syncTime"
        labelText="Vreme za sinhronizaciju u minutima"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
        min="1"
      />
      <CustomFormDropDown
        customForm={form}
        name="batteryType"
        labelText="Napajanje"
        formItemStyle="flex w-full flex-col px-2 pb-5 max-w-[520px] mt-2 sm:mt-5"
        items={batteryTypesSENS}
        placeholder="Odaberite vrstu napajanja"
      />
      <CustomFormDropDown
        customForm={form}
        name="zoneNumber"
        labelText="Broj zona"
        items={sensDeviceZoneNumbers}
        formItemStyle="flex w-full flex-col px-2 max-w-[520px] mt-2 sm:mt-5"
        placeholder="Odaberite broj zona"
      />
    </>
  );
};

export default SensDeviceInfoForm;
