import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import {
  setNumberOfActiveParcels,
  setNumberOfActiveFERTs,
  setNumberOfParcels,
  setNumberOfActiveSENSs,
  setNumberOfActiveSTARTs,
  setNumberOfUsersByCountry,
  setUtilization,
  setNumberOfNotActiveSENSs,
  setNumberOfNotActiveSTARTs,
  setNumberOfNotActiveFERTs,
  setNumberOfSubscribedUsers,
  setNumberOfNotSubscribedUsers,
  setNotSubscribedUsers,
} from '@/redux/statistics/statistics.slice.ts';
import {
  AllNotSubscribedUsersResponse,
  DeviceConnectivityStatisticsResponse,
  GetCountOfDevices,
  GetParcelStatisticsResponse,
  GetUserStatisticsResponse,
  SubscribedUsersResponse,
} from '@/redux/statistics/statistics.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getUserStatistics = createAsyncThunk<GetUserStatisticsResponse, void>(
  'admin/get-user-statistics',
  async (_, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetUserStatisticsResponse>(
        `/admin/user/get-statistics`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(
        setNumberOfUsersByCountry(
          axiosResponse.data.content.numberOfUsersByCountry
        )
      );
      dispatch(setUtilization(axiosResponse.data.content.userUtilization));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getParcelStatistics = createAsyncThunk<GetParcelStatisticsResponse, void>(
  'admin/get-parcel-statistics',
  async (_, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetParcelStatisticsResponse>(
        `/admin/parcel/get-statistics`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(
        setNumberOfActiveParcels(
          axiosResponse.data.content.numberOfActiveParcels
        )
      );
      dispatch(setNumberOfParcels(axiosResponse.data.content.numberOfParcels));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getCountDevices = createAsyncThunk<GetCountOfDevices, void>(
  'admin/get-count-devices',
  async (_, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetCountOfDevices>(
        `/admin/device/count-devices`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      const {
        SENSActive,
        STARTActive,
        FERTActive,
        SENSNotActive,
        STARTNotActive,
        FERTNotActive,
      } = axiosResponse.data.content;

      dispatch(setNumberOfActiveSENSs(SENSActive));
      dispatch(setNumberOfActiveSTARTs(STARTActive));
      dispatch(setNumberOfActiveFERTs(FERTActive));
      dispatch(setNumberOfNotActiveSENSs(SENSNotActive));
      dispatch(setNumberOfNotActiveSTARTs(STARTNotActive));
      dispatch(setNumberOfNotActiveFERTs(FERTNotActive));

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getNumberOfSubscribedUsersAction = createAsyncThunk<
  SubscribedUsersResponse,
  void
>('admin/get-subscribed-users', async (_, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<SubscribedUsersResponse>(
      `/admin/user/get-subscribed-statistics`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setNumberOfSubscribedUsers(axiosResponse.data.content.subscribed));
    dispatch(
      setNumberOfNotSubscribedUsers(axiosResponse.data.content.notSubscribed)
    );
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getAllNotSubscribedUsers = createAsyncThunk<
  AllNotSubscribedUsersResponse,
  void
>(`admin/get-not-subscribed-users`, async (_, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<AllNotSubscribedUsersResponse>(
      `/admin/user/get-not-subscribed-users`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setNotSubscribedUsers(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getDeviceConnectivityStatistics = createAsyncThunk<
  DeviceConnectivityStatisticsResponse,
  { lowBatteryPercentage: number }
>('admin/get-device-connectivity-statistics', async (reqData) => {
  try {
    const axiosResponse =
      await axiosServer.get<DeviceConnectivityStatisticsResponse>(
        `/admin/device/get-device-connectivity-statistics?lowBatteryPercentage=${reqData.lowBatteryPercentage}`
      );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export {
  getUserStatistics,
  getParcelStatistics,
  getCountDevices,
  getNumberOfSubscribedUsersAction,
  getAllNotSubscribedUsers,
  getDeviceConnectivityStatistics,
};
