import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { setCities } from '@/redux/countries/countries.slice.ts';
import { CitiesResponse } from '@/redux/countries/countries.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getCitiesApi = createAsyncThunk<CitiesResponse, string>(
  'get-countries',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.post<CitiesResponse>(
        '/countries/cities',
        { country: reqData }
      );

      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setCities(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

export { getCitiesApi };
