import { Check, ChevronsUpDown } from 'lucide-react';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Button } from '@/components/ui/button.tsx';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command.tsx';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@/components/ui/popover.tsx';
import { cn } from '@/lib/utils.ts';

export type ComboboxItemType = {
  value: string;
  label: string;
};

type CustomFormComboboxProps = {
  customForm: UseFormReturn<any, any, any>;
  name: string;
  labelText?: string;
  formItemStyle?: string;
  labelStyle?: string;
  buttonStyle?: string;
  items: ComboboxItemType[];
  placeholder: string;
  onChange?: (selectedValue: string) => void;
};

const CustomFormCombobox: FC<CustomFormComboboxProps> = ({
  customForm,
  formItemStyle,
  labelStyle,
  buttonStyle,
  labelText,
  name,
  items,
  placeholder,
  onChange,
}) => {
  return (
    <FormField
      control={customForm.control}
      name={name}
      render={({ field }) => (
        <FormItem className={formItemStyle}>
          <FormLabel className={labelStyle}>{labelText}</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    buttonStyle,
                    !field.value && 'text-muted-foreground'
                  )}
                >
                  {field.value
                    ? items.find((item) => item.value === field.value)?.label
                    : placeholder}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="min-w-[300px] p-0">
              <Command>
                <CommandInput placeholder="Pretražite..." />
                <CommandList>
                  <CommandEmpty>Nema rezultata pretrage.</CommandEmpty>
                  <CommandGroup>
                    {items.map((item) => (
                      <CommandItem
                        value={item.label}
                        key={item.value}
                        onSelect={() => {
                          field.onChange(item.value);
                          customForm.setValue(name, item.value);
                          onChange?.(item.value);
                        }}
                      >
                        <Check
                          className={cn(
                            'mr-2 h-4 w-4',
                            item.value === field.value
                              ? 'opacity-100'
                              : 'opacity-0'
                          )}
                        />
                        {item.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default CustomFormCombobox;
