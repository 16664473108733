import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DevicesContent from '@/components/layout/devices/DevicesContent.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';

const DevicesConnectivityDetailsPage: FC = () => {
  const params = useParams();

  const [pageTitle, setPageTitle] = useState<string>('');

  useEffect(() => {
    const definePageTitle = async () => {
      // @ts-ignore
      if (params.connectivityFilter === 'online') {
        setPageTitle('Lista konektovanih uređaja');
      }

      if (params.connectivityFilter === 'offline') {
        setPageTitle('Lista nekonektovanih uređaja');
      }

      if (params.connectivityFilter === 'low-battery') {
        setPageTitle('Lista uređaja sa praznom baterijom');
      }
    };

    definePageTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0">
          <div className="flex flex-row max-sm:flex-col sm:justify-between items-center max-sm:items-end">
            <p className="text-xl font-bold">{pageTitle}</p>
          </div>
          <DevicesContent />
        </div>
      </div>
    </div>
  );
};

export default DevicesConnectivityDetailsPage;
