import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getDeviceConnectivityStatistics } from '@/redux/statistics/statistics.actions.ts';
import { DeviceConnectivityStatisticsResponse } from '@/redux/statistics/statistics.types.ts';

export const lowBatteryPercentage = 20;

const DeviceConnectivityStatistic: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const [onlineDeviceCount, setOnlineDeviceCount] = useState<number>(0);
  const [offlineDeviceCount, setOfflineDeviceCount] = useState<number>(0);
  const [lowBatteryDeviceCount, setLowBatteryDeviceCount] = useState<number>(0);

  const onDisplayOnlineDevicesClick = () => {
    if (onlineDeviceCount === 0) {
      return;
    }
    router(`/devices-connectivity-details/online`);
  };

  const onDisplayLowBatteryDevicesClick = () => {
    if (lowBatteryDeviceCount === 0) {
      return;
    }
    router(`/devices-connectivity-details/low-battery`);
  };

  const onDisplayOfflineDevicesClick = () => {
    if (offlineDeviceCount === 0) {
      return;
    }
    router(`/devices-connectivity-details/offline`);
  };

  const handleErrorResponse = (
    response: DeviceConnectivityStatisticsResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchDeviceConnectivityStatistic = async () => {
      const deviceConnectivityResponse = await dispatch(
        getDeviceConnectivityStatistics({
          lowBatteryPercentage,
        })
      ).unwrap();

      if (!deviceConnectivityResponse.success) {
        handleErrorResponse(deviceConnectivityResponse);
      }

      setOnlineDeviceCount(
        deviceConnectivityResponse.content.onlineDevicesCount
      );

      setOfflineDeviceCount(
        deviceConnectivityResponse.content.offlineDevicesCount
      );

      setLowBatteryDeviceCount(
        deviceConnectivityResponse.content.lowBatteryDevicesCount
      );
    };

    fetchDeviceConnectivityStatistic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Statistika konektovanosti uređaja</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-row gap-5 max-md:flex-col">
          <div className="flex w-[50%] max-md:w-full">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Konektovani uređaji</CardTitle>
              </CardHeader>
              <CardContent className="device_connectivity_number">
                {onlineDeviceCount}
              </CardContent>
              <CardFooter>
                <Button onClick={onDisplayOnlineDevicesClick}>
                  Prikaži sve
                </Button>
              </CardFooter>
            </Card>
          </div>
          <div className="flex w-[50%] max-md:w-full">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Uređaji sa praznom baterijom</CardTitle>
              </CardHeader>
              <CardContent className="device_connectivity_number">
                {lowBatteryDeviceCount}
              </CardContent>
              <CardFooter>
                <Button onClick={onDisplayLowBatteryDevicesClick}>
                  Prikaži sve
                </Button>
              </CardFooter>
            </Card>
          </div>
          <div className="flex w-[50%] max-md:w-full">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Nekonektovani uređaji</CardTitle>
              </CardHeader>
              <CardContent className="device_connectivity_number">
                {offlineDeviceCount}
              </CardContent>
              <CardFooter>
                <Button onClick={onDisplayOfflineDevicesClick}>
                  Prikaži sve
                </Button>
              </CardFooter>
            </Card>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
export default DeviceConnectivityStatistic;
