import { FC, useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormCheckbox from '@/components/controls/CustomFormCheckbox.tsx';
import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getCitiesApi } from '@/redux/countries/countries.actions.ts';
import { CitiesResponse } from '@/redux/countries/countries.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { countries } from '@/utils/countryUtil.ts';

type BillingAddressFormProps = {
  form: UseFormReturn<any>;
  isFormShown: boolean;
};

const BillingAddressForm: FC<BillingAddressFormProps> = ({
  form,
  isFormShown,
}) => {
  const countriesComboboxContent: ComboboxItemType[] = countries.map(
    (country) => {
      return {
        value: country,
        label: country,
      };
    }
  );
  const selectedCountry = useWatch({
    control: form.control,
    name: 'billingCountry',
  });
  const [cities, setCities] = useState<ComboboxItemType[]>([]);
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: CitiesResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchCities = async () => {
      const response = await dispatch(getCitiesApi(selectedCountry)).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }

      setCities(response.content.map((city) => ({ value: city, label: city })));
    };

    if (selectedCountry) {
      fetchCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  return (
    <div className="mb-5">
      <CustomFormCheckbox
        form={form}
        name="differentBillingAddress"
        labelText="Da li se adresa za dostavu računa razlikuje?"
        formItemStyle="flex flex-col gap-2 mt-5 px-2"
        checkboxStyle="w-5 h-5 data-[state=checked]:bg-[#007bff] data-[state=checked]:text-neutral-50"
      />
      {isFormShown && (
        <>
          <CustomFormCombobox
            customForm={form}
            name="billingCountry"
            labelText="Država dostave"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full w-[300px]"
            buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
            items={countriesComboboxContent}
            placeholder="Odaberite državu"
          />
          <CustomFormCombobox
            customForm={form}
            name="billingPlace"
            labelText="Mesto dostave"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full w-[300px]"
            buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
            items={cities}
            placeholder="Odaberite mesto dostave"
          />
          <CustomFormInput
            customForm={form}
            name="billingAddress"
            labelText="Adresa dostave"
            formItemStyle="flex flex-col w-full pb-5 mt-5 px-2"
            labelStyle="max-sm:w-full sm:w-[300px]"
            divStyle="max-md:w-full md:w-[500px]"
            type="text"
          />
        </>
      )}
    </div>
  );
};

export default BillingAddressForm;
