import { FC } from 'react';

import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import DeviceConnectivityStatistic from '@/components/layout/statistics/DeviceConnectivityStatistic.tsx';
import DeviceCountStatistic from '@/components/layout/statistics/DeviceCountStatistic.tsx';
import HumidityStatusStatistic from '@/components/layout/statistics/HumidityStatusStatistic';
import NotSubscribedTable from '@/components/layout/statistics/NotSubscribedTable.tsx';
import ParcelStatistic from '@/components/layout/statistics/ParcelStatistic.tsx';
import SubscribedUsersStatistic from '@/components/layout/statistics/SubscribedUsersStatistic.tsx';
import UserStatistic from '@/components/layout/statistics/UserStatistic.tsx';

const HomePage: FC = () => {
  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
          <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
            <UserStatistic />
          </div>
          <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
            <ParcelStatistic />
          </div>
          <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
            <HumidityStatusStatistic />
          </div>
          <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
            <DeviceConnectivityStatistic />
          </div>
          <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
            <DeviceCountStatistic />
          </div>
          <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
            <SubscribedUsersStatistic />
          </div>
          <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
            <NotSubscribedTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
