export const getDisplayZone = (humidityStatus: string) => {
  if (humidityStatus === 'saturated') {
    return 'zasićeno';
  }

  if (humidityStatus === 'wet') {
    return 'vlažno';
  }

  if (humidityStatus === 'moderate') {
    return 'umereno';
  }

  return 'suvo';
};
